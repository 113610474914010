import {booleanAttribute, Component, EventEmitter, Input, Output} from '@angular/core';

import {NgClass} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {KeenIconComponent} from '@shared/components/keen-icon/keen-icon.component';

@Component({
  standalone: true,
  selector: 'ups-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  imports: [
    KeenIconComponent,
    NgClass,
    TranslateModule
  ]
})
export class ButtonComponent {
  @Input() label = '';
  @Input() isPending = false;
  @Input() widthFull = false;
  @Input() isLightIcon = false;
  @Input() color = '';
  @Input() icon = '';
  @Input() samllicon = '';
  @Input() size: 'lg' | 'sm' | 'cl' | 'xl-icon' | '' = 'sm';
  @Input() class: string | string[] = '';
  @Input() customClass: string | string[] = '';
  @Input({transform: booleanAttribute}) disabled = false;
  @Input() type: 'button' | 'reset' | 'submit' = 'button';
  @Input() styleType:
    | 'success'
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'light'
    | 'dark' = 'primary';
  @Output() btnClick = new EventEmitter<void>();

  getClass() {
    let customClass = [
      'btn',
      `btn-${this.styleType}`,
      `btn-${this.size}`,
      'fw-bold',
      'border-0',
      'btn-outline',
      'align-items-center'
    ];
    if (this.customClass) {
      customClass =
        Array.isArray(this.customClass) ?
          this.customClass :
          this.customClass.split(' ');
    }
    return [
      ...customClass,
      ...(Array.isArray(this.class) ? this.class : this.class.split(' '))
    ];
  }
}
