import {Component, EventEmitter, inject, Input, OnInit, Optional, Output, Self,} from '@angular/core';
import {ControlValueAccessor, FormControl, FormGroupDirective, NgControl, ValidationErrors,} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ErrorList} from './errorList';

@Component({
  template: '',
})
export abstract class BasicFormInputComponent
  implements OnInit, ControlValueAccessor {
  @Input() customClass = '';
  @Input() placeholder = '';
  @Input() label = '';
  @Input() required = false;
  @Input() width: string;
  @Input() size: 'xs' | 'sm' | 'lg' | '' = 'sm';
  @Input() specialCharacters: string[] = [];
  @Input() shownMaskExpression: string | null = null;
  @Input() disable: boolean = false;
  @Input() clearable = true;
  @Output() selectChange = new EventEmitter<any>();
  onTouched: () => void;
  id = Math.floor(Math.random() * 10000).toString();
  _translateService = inject(TranslateService);
  disabled: boolean;
  formControl: FormControl;

  constructor(
    @Self() @Optional() private controlDir: NgControl,
    @Optional() protected parent: FormGroupDirective
  ) {
    if (controlDir) {
      controlDir.valueAccessor = this;
    }
  }

  protected _value: any;

  @Input() get value(): any {
    return this._value;
  }

  set value(value: any) {
    this._value = value;
  }

  onChange(value: any) {
    this.selectChange.emit(value);
  }

  ngOnInit() {
    if (this.controlDir) {
      this.formControl = this.controlDir.control as FormControl;
    }
  }

  showValidationErrors() {
    return (
      this.formControl?.invalid &&
      (this.formControl?.touched || this.formControl?.dirty)
    );
  }

  errorMessage(): string[] {
    const result: string[] = [];
    const controlErrors: ValidationErrors | null = this.formControl.errors;
    if (controlErrors) {
      Object.keys(controlErrors).forEach((key) => {
        let errorMessage = '';
        if (key === 'error') {
          errorMessage = controlErrors[key] ?? '';
        } else {
          errorMessage = this._translateService.instant(
            'ERROR_LIST.' + ErrorList[key],
            controlErrors[key]
          );
        }
        result.push(errorMessage);
      });
    }
    return result;
  }

  errorMessagesHtml() {
    return '<div>' + this.errorMessage().join('</div><div>') + '</div>';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  valueChange(value: string) {
    this.onChange(value);
  }

  clear() {
    if (this.formControl) {
      this.formControl.setValue('');
    }
  }
}
