<button
  (click)="btnClick.emit()"
  [class.d-flex]="icon"
  [disabled]="disabled"
  [ngClass]="getClass()"
  [type]="type"
>
  @if (isPending) {
    <div
      class="loadingio-spinner-spinner-0qb0ddr5bwvb"
      style="margin-right: 5px"
    >
      <div class="ldio-ng4stnl1cgl">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  }
  @if (icon) {
    <keen-icon
      [name]="icon"
      class="{{ isLightIcon ? 'font-medium-2 text-light' : 'font-medium-2' }}"
      style="padding-top: 5px"
      [style.color]="color"
    />
  }
  @if (samllicon) {
    <keen-icon [style.color]="color" class="font-small-1 me-2" name="file-up"/>
  }
  <span [class.ms-2]="icon" [translate]="label" class="label"></span>
</button>
