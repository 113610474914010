import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'ups-input-label',
  templateUrl: './input-label.component.html',
  styleUrls: ['./input-label.component.scss'],
  imports: [
    CommonModule,
    TranslateModule
  ]
})
export class InputLabelComponent {
  @Input() width: string;
  @Input() label = '';
  @Input() for = '';
  @Input() customClass = '';
  @Input() required = false;
  @Input() size: 'sm' | 'lg' | '' = 'sm';
}
