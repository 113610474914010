<!--<label-->
<!--  [class]="customClass"-->
<!--  [for]="for"-->
<!--  [style.width]="width"-->
<!--  class="font-medium-1"-->
<!--  style="color: #7e8299; font-weight: 500; display: inline-block"-->
<!--&gt;-->
<!--  {{ label | translate }}-->
<!--  @if (required) {-->
<!--    <span style="color: red">*</span>-->
<!--  }-->
<!--</label>-->

<div [ngClass]="{'required': required}" style="margin-top: -2px !important;">
  <label
    [for]="for"
    class="label-small"
  >{{ label | translate }}</label>
</div>
